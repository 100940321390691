import React, { useState, useContext } from "react"
import Seo from "../components/seo"
import { AuthContext } from "../context/auth"
import firebase from "gatsby-plugin-firebase"
import { Link, navigate } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Statistic } from "antd"
import cellopic from "../images/brand/cello_1000x2100.jpeg"

const RegisterRedirect = () => {
  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    error: null,
  })

  const { Countdown } = Statistic
  const deadline = Date.now() + 1000 * 11 // Moment is also OK

  function onFinish() {
    console.log("finished!")
  }

  const handleRedirect = () => {
    navigate("/login", { state: { name: data.firstName } })
  }

  return (
    <main>
      <div
        className="hidden md:block absolute top-0 bottom-0 right-0 md:w-1/2"
        aria-hidden="true"
      >
        <img
          className="object-cover object-center w-full h-full"
          src={cellopic}
          alt=""
          width="760"
          height="1024"
        />
      </div>
      <Seo title="Login" />
      <div className="relative sm:flex">
        <div className="sm:w-1/2 bg-white">
          <div className="max-w-sm mx-auto min-h-screen flex flex-col justify-center px-4 py-8">
            <div className="w-full">
              <h1 className="text-3xl text-gray-800 font-bold mb-6">
                <Link to="/">
                  <div className="">
                    <StaticImage
                      src="../images/siyo-logo.png"
                      width={80}
                      quality={100}
                      alt="Si-Yo Music Logo"
                    />
                    <span> </span>
                    <StaticImage
                      src="../images/ficec-logo-dark.png"
                      width={90}
                      quality={100}
                      alt="FICEC Logo"
                    />
                  </div>
                </Link>
                <br />
              </h1>
              <h1>Congratulations! </h1>
              <h3>You have successfully registered.</h3>
              <p>
                We have sent an email with a confirmation link to your email
                address. In order to complete the sign-up process, please click
                the confirmation link.
              </p>
              <span className="text-green-500">
                You will redirect to Login page in
                <Countdown
                  value={deadline}
                  onFinish={handleRedirect}
                  valueStyle={{ color: "#3f8600" }}
                  format="ss"
                />
                seconds
              </span>

              {/* Footer */}
              <div className="pt-5 mt-6 border-t border-gray-200">
                <div className="text-sm">
                  Have an account?{" "}
                  <Link
                    className="font-medium text-indigo-500 hover:text-indigo-600"
                    to="/login"
                  >
                    Log In
                  </Link>
                </div>

                {/* warning message */}
                {data.error ? (
                  <div className="mt-5">
                    <div className="bg-yellow-100 text-yellow-600 px-3 py-2 rounded">
                      <svg
                        className="inline w-3 h-3 flex-shrink-0 fill-current mr-2"
                        viewBox="0 0 12 12"
                      >
                        <path d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z" />
                      </svg>
                      <span className="text-sm">{data.error}</span>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default RegisterRedirect
